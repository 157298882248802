<template>
  <div class="report">
    <div class="reportCgicop">
      <div class="image">
        <img src="../assets/image/spacialBanner.bc11f27.png" alt="" style="width: 100%; height: 100%;">
      </div>
      <div class="layout">
        <div class="listHd clearfloat">
          <div class="fl listHdGrop">
            {{$t('m.listPageOption')}}：<span style="color: #0162b3;">&nbsp;纪检举报</span>
          </div>
        </div>
        <div class="title">中甘国际纪委网络举报中心</div> 

        <div class="content">
          <div class="view">
            <!-- 举报人信息 -->
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>举报人信息</span>
                </div>
                <div >                
                  <el-form-item label="举报人姓名：" prop="jbUserName">
                    <el-input v-model="ruleForm.jbUserName" clearable placeholder="举报人姓名"></el-input>
                  </el-form-item>
                  <el-form-item label="联系电话：" prop="jbTel">
                    <el-input v-model="ruleForm.jbTel" clearable type="number" placeholder="请输入手机号"></el-input>
                  </el-form-item>
                  <el-form-item label="您的单位或地址：" prop="jbUserWorkUnit">
                    <el-input v-model="ruleForm.jbUserWorkUnit" clearable placeholder="请输入您的单位或地址"></el-input>
                  </el-form-item>              
                </div>
              </el-card>
              <!-- 被举报人信息 -->
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>被举报人信息</span>
                </div>
                <div >               
                  <el-form-item label="被举报人姓名：" prop="bjbUserName">
                    <el-input v-model="ruleForm.bjbUserName" clearable placeholder="请输入被举报人姓名"></el-input>
                  </el-form-item>
                  <el-form-item label="被举报人单位：" prop="bjbUserWorkUnit">
                    <el-input v-model="ruleForm.bjbUserWorkUnit" clearable placeholder="请输入被举报人单位"></el-input>
                  </el-form-item>                
                </div>
              </el-card>
              <!-- 举报信息 -->
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>举报信息</span>
                </div>
                <div >
                  <el-form-item label="举报标题" prop="jbTitle">
                    <el-input v-model="ruleForm.jbTitle" clearable placeholder="请输入举报标题"></el-input>
                  </el-form-item>
                  <el-form-item label="举报内容" prop="jbContent">
                    <el-input type="textarea" :rows="4" clearable v-model="ruleForm.jbContent"  placeholder="请输入内容"></el-input>
                  </el-form-item>
                  <el-form-item label="验证码" prop="code">
                    <el-input
                      class="code"
                      clearable
                      type="number"
                      style="width: 43%;"
                      v-model="ruleForm.code"
                      placeholder="请输入验证码"
                    ></el-input>
                    <div class="coderight" @click="refreshCode" style="display:inline-block;height:40px;vertical-align:top;cursor:pointer;width:40%;margin-left:2%">
                      <SIdentify 
                      :identifyCode="identifyCode" 
                      :contentHeight="40"
                      :contentWidth="148"
                      ></SIdentify>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                  </el-form-item>
                </div>
              </el-card>
            </el-form>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SIdentify from "../views/captcha/index.vue"; 
import { addAccuseInform } from '@/api/common'
export default {
  components: {
    SIdentify: SIdentify,
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('电话号码不能为空'))
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,实际输入的数字总是识别成字符串 😀
        // 所以在前面加了一个+实现隐式转换
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('电话号码格式不正确'))
          }
        }
      }, 100)
    }
    return {      
      mathedTitle:'在线举报',   
      code: "6666",
      identifyCodes: "1234567890",
      identifyCode: "",
      loading: false,
      ruleForm: {
        jbUserName: '',
        jbTel: '',
        jbUserWorkUnit: '',
        bjbUserName: '',
        bjbUserWorkUnit: '',
        jbTitle: '',
        jbContent: '',
        code: '',
        applicationUuid: this.webId
      },
      rules: {
        jbUserName: [
          { required: true, message: '请输入举报人姓名', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        jbTel: [
          { required: true, message: "请输入正确的手机号",trigger: "blur"},
          { validator: checkPhone, trigger: 'blur' } 
        ],
        bjbUserName: [
          { required: true, message: '请输入被举报人姓名', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        bjbUserWorkUnit: [
          { required: true, message: '请输入被举报人单位', trigger: 'blur' }
        ],
        jbContent: [
          { required: true, message: '举报内容字数过少', trigger: 'blur' },
          { min: 2, message: '举报内容字数过少', trigger: 'blur' }
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    }
  },
  computed: {
  },
  mounted() {
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
  methods: {
    // 提交
    submitForm(formName) {
      var _this = this
      this.loading = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.ruleForm.code) {
            this.$message({
              message: '请输入验证码',
              type: 'warning'
            });
            return;
          }
          else if(this.ruleForm.code != this.identifyCode){
            this.refreshCode()
            this.$message({
              message: '验证码错误',
              type: 'warning'
            });       
            return;
          }
          addAccuseInform(_this.ruleForm).then(res => {
            const { code } = res
            if (code === 1) {
              this.loading = false
              this.$confirm('提交成功, 我们会及时联系您！', '提示', {
                showConfirmButton: false,
                cancelButtonText: '确定',
                type: 'success',
                center: true
              })
              .then(() =>{
              })
              .catch(() =>{
                _this.resetForm('ruleForm')
              })
            } else{
              this.loading = false
              this.$message({
                type: 'warning',
                message: '提交失败，请稍后重试!'
              })
              }
            })
           }
            else {
              console.log('error submit!!')
              return false
            }
          });
        },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //验证码abcdefghijklnmopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
  },
  watch:{
    $route(val){
      this.fullpath=val.fullPath
      this.pathtitle=val.meta.title
      this.mathedTitle=val.matched[val.matched.length-2].meta.title
    }
  } 
}
</script>

<style lang="scss">

.clear {
  width: 100%;
  height: 20px;
  overflow: hidden;
  display: block;
  clear: both;
}
.content {
  font-size: 16px;
  line-height: 30px;
  padding: 0px;
  margin-top: 10px;
}
.content .view {
  padding: 0px 10px;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}
.box-card {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.el-card__header {
  padding: 10px 20px;
  border-bottom: 1px solid #EBEEF5;
  box-sizing: border-box;
  font-weight: 600;
}
.report .el-input__inner {
  width: 60%;
  font-size: 14px;
  color: #606266;
  border-radius: 2px;
}
.report .code .el-input__inner {
  width: 100%;
  font-size: 14px;
  color: #606266;
  border-radius: 2px;
}
.report .el-textarea__inner {
  width: 60%;
  font-size: 14px;
  color: #606266;
  border-radius: 2px;
}
.el-form {
  font-weight: 400;
  font-size: 16px;
}
.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.listHd {
  height: 50px;
  display: flex;
  line-height: 50px;
}
.listHdGrop {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333;
  opacity: .6;
}
.reportCgicop .image {
  width: 100%;
  height: 417px;
  overflow: hidden;
  min-width: 1200px;
  max-height: 330px;
  display: flex;
  margin: 0 auto;
  border: none;
  position: relative;
  z-index: 99;
}
.layout {
  width: 1200px;
  margin: 0 auto;
}
.reportCgicop .title {
  width: 1182px;
  background: #0162b3;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 700;
  color: #fff;
  text-align: center;
  height: 50px;
  line-height: 50px;
  /* margin: 20px auto; */
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}
</style>

